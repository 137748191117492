<template>
  <div class="flex flex-col gap-6">
    <div class="flex gap-2">
      <div
        v-for="answer in answers"
        :key="answer.id"
        class="flex gap-2 rounded-lg bg-v-ui-root-monochrome-5 px-4 py-2 w-full"
      >
        <VRadio
          :id="answer.id"
          v-model="subjectGroup"
          :current-value="answer"
        />{{ answer.title }}
      </div>
    </div>
    <div>
      <VButton
        label="Включить в исследование"
        size="m"
        :class="{
          'bg-v-ui-root-monochrome-10 text-v-ui-text-monochrome-27 pointer-events-none':
            isDisabled,
        }"
        :disabled="isDisabled"
        :loading="isLoading"
        @click="confirmSubjectGroup"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SUBJECT_GROUP } from '@/consts'
import CoreApi from '@/api/coreApi'
import { VRadio, VButton } from '@doctis.front/doctis.designsystem'

const coreApi = new CoreApi()

export default {
  components: {
    VRadio,
    VButton,
  },
  data() {
    return {
      subjectGroup: null,
      answers: [SUBJECT_GROUP[1], SUBJECT_GROUP[0]],
      isLoading: false,
    }
  },
  computed: {
    ...mapState('research', ['research']),
    isDisabled() {
      return Boolean(!this.subjectGroup)
    },
  },
  methods: {
    ...mapActions({
      researchByPatient: 'research/researchByPatient',
    }),
    async confirmSubjectGroup() {
      const params = {
        assignment: this.research.assignment_id,
        group: this.subjectGroup.value,
      }
      try {
        this.isLoading = true
        await coreApi.addGroup(params)
        await this.researchByPatient(true)
        this.$emit('group-added', this.subjectGroup.value)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
