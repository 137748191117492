<template>
  <div class="wrapped-content">
    <div>
      <div class="title">{{ title }}</div>
      <div class="patient">{{ patientName }}</div>
    </div>
    <div class="indicators">
      <Icon name="expand" @click="setWidgetState('pin')" />
      <Icon name="close" @click="setWidgetState('close')" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'WrappedContent',
  data() {
    return {
      title: 'Включение в исследование',
      patientName: 'Иванов Р.А.',
    }
  },
  methods: {
    ...mapMutations(['setWidgetState']),
  },
}
</script>

<style lang="scss" scoped>
.wrapped-content {
  @apply flex justify-between bg-v-ui-root-main rounded-lg p-3 text-v-ui-root-monochrome-0;
  fill: var(--white);
  color: var(--white);

  .title {
    margin-bottom: 4px;
    font-weight: 500;
  }

  .patient {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .indicators {
    .icon {
      cursor: pointer;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        fill: var(--dont-gray);
      }
    }
  }
}
</style>
