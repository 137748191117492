<template>
  <div class="flex flex-col gap-4 w-[328px] p-4">
    <p class="title-20-medium-500">
      Кандидат не соответствует критериям исследования
    </p>
    <p class="text-v-ui-text-monochrome-55">Выберите другого кандидата</p>
    <VButton label="Хорошо" size="m" @click="closeModal" />
  </div>
</template>

<script>
import { VButton } from '@doctis.front/doctis.designsystem'

export default {
  components: {
    VButton,
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>

<style></style>
