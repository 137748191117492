<template>
  <div class="flex flex-col gap-2">
    <div class="title-14-medium-500 text-v-ui-text-monochrome-55">
      Заполните дату подписания информационного листка с формой информированного
      согласия
    </div>
    <VDatePicker
      v-model="date"
      :error-label="errorMessage"
      class="w-48"
      v-bind="datePickerDates"
      action-handler="input"
      @update:invalid="onUpdateBirthDateInvalid"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VDatePicker } from '@doctis.front/doctis.designsystem'

export default {
  components: {
    VDatePicker,
  },
  data() {
    return {
      date: new Date(),
      dateIsCorrect: false,
      errorMessage: 'Нельзя выбрать будущую дату',
      datePickerDates: {
        initialDate: new Date(),
        startDate: new Date('1000', '1', '1'),
        endDate: new Date(),
      },
    }
  },
  watch: {
    date(value) {
      if (value) this.setDate(value)
    },
  },
  methods: {
    ...mapActions({
      setDate: 'research/setDate',
    }),
    onUpdateBirthDateInvalid(isInvalidDate) {
      const selectedDate = isInvalidDate ? null : this.date
      this.setDate(selectedDate)
    },
  },
}
</script>
