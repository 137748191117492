<template>
  <div class="relative flex flex-col gap-6">
    <VDescription
      id="criteria-description"
      :text="description"
      class="!border-l-0"
    />
    <VSkeleton
      v-for="i in 4"
      v-show="isLoadingQuestions"
      :key="i"
      class="w-full h-24 rounded-lg bg-v-ui-light-service-info-monochrome"
    />
    <CriteriaQuestion
      v-for="question in questions"
      :key="question.id"
      :question="question"
      :answer-key="criteriaType"
      @set-answer="setAnswer"
    />
    <div>
      <VButton label="Подтвердить" size="m" @click="confirmCriteria" />
    </div>

    <div
      v-if="isLoading"
      class="absolute flex justify-center items-center w-full h-full bg-v-ui-overlay-monochrome-10"
    >
      <Preloader />
    </div>
  </div>
</template>

<script>
import CoreApi from '@/api/coreApi'
import { mapState, mapActions } from 'vuex'
import {
  VDescription,
  VButton,
  VSkeleton,
} from '@doctis.front/doctis.designsystem'
import CriteriaQuestion from './CriteriaQuestion.vue'
import Preloader from '@/components/base/Preloader.vue'

const coreApi = new CoreApi()

export default {
  components: {
    VDescription,
    VButton,
    CriteriaQuestion,
    Preloader,
    VSkeleton,
  },
  props: {
    criteriaType: {
      type: Number,
      default: 0,
      validator(value) {
        return [0, 1].includes(value)
      },
    },
  },
  data() {
    return {
      description:
        'Все критерии автоматически установлены для пациентов, подходящих выбранному исследованию. Если вы согласны, нажмите кнопку Подтвердить в нижней части списка или выберите другого кандидата',
      questions: [],
      answers: [],
      isLoading: false,
      isLoadingQuestions: false,
    }
  },
  computed: {
    ...mapState('research', ['research']),
  },
  async mounted() {
    try {
      this.isLoadingQuestions = true
      const params = {
        assignment: this.research.assignment_id,
        inclusion_criteria_type: this.criteriaType,
      }
      this.questions = await coreApi.inclusionCriterials(params)
    } finally {
      this.isLoadingQuestions = false
    }
  },
  methods: {
    ...mapActions({
      researchByPatient: 'research/researchByPatient',
    }),
    setAnswer(answer) {
      const idx = this.answers.findIndex((ans) => ans.id === answer.id)
      if (idx === -1) {
        this.answers.push(answer)
      } else {
        this.answers.splice(idx, 1, answer)
      }
    },
    async confirmCriteria() {
      try {
        this.isLoading = true
        const params = {
          assignment: this.research.assignment_id,
          inclusion_criteria_type: this.criteriaType,
          select_criterials: this.answers.map((answer) => ({
            id: answer.id,
            answer: answer.answer,
          })),
        }

        const { result } = await coreApi.addAssignmentSelectCriterials(params)

        await this.researchByPatient(true)

        this.$emit('confirm-criteria', result)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
#criteria-description {
  border-color: transparent;
}

.v-skeleton {
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>
