<template>
  <div v-if="init" id="widget">
    <WidgetContainer>
      <template #default>
        <FormContainer />
      </template>
      <template #wrapped>
        <WrappedContent />
      </template>
    </WidgetContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from './store/index'
import WidgetContainer from '@/components/widget/WidgetContainer'
import FormContainer from '@/components/form/FormContainer.vue'
import WrappedContent from '@/components/WrappedContent'

export default {
  name: 'App',
  store: store,
  components: {
    WidgetContainer,
    FormContainer,
    WrappedContent,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['init']),
    ...mapState('auth', ['user']),
  },
  async mounted() {
    //   // Для теста
    if (window.dwidgetclinicalresearches) {
      // eslint-disable-next-line no-undef
      await dwidgetclinicalresearches.initiate()
    }
    // if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    //   if (window.dwidgetclinicalresearches) {
    //     // eslint-disable-next-line no-undef
    //     await dwidgetclinicalresearches.initiate()
    //   }
    // }
  },
}
</script>

<style lang="scss">
// Стили для теста
body {
  background: #f0f0f0;
  padding: 0;
  margin: 0;
}
</style>
