<template>
  <div
    id="widget-container"
    ref="widget"
    v-drag="{ handle: `.widget-indicators-unpin` }"
    :class="['widget-container', widgetState]"
    @v-drag-end="dragEnd"
  >
    <WidgetIndicators class="widget-indicators-unpin" />
    <WidgetIndicators class="widget-indicators-pin" />

    <div class="content">
      <slot v-if="isWrap" name="wrapped" />
      <slot v-else />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WidgetIndicators from '@/components/widget/WidgetIndicators'

export default {
  name: 'WidgetContainer',
  components: { WidgetIndicators },
  computed: {
    ...mapState(['widgetState']),
    isWrap() {
      return this.widgetState === 'wrap'
    },
  },
  watch: {
    widgetState() {
      this.$refs.widget.style.left = ''
      this.$refs.widget.style.top = ''
      this.$refs.widget.style.height = ''
      this.$refs.widget.style.width = ''
    },
  },
  methods: {
    dragEnd() {
      switch (this.widgetState) {
        case 'unpin': {
          const { x, y } = this.$refs.widget.getBoundingClientRect()
          if (x < 0) this.$refs.widget.style.left = 0
          if (y < 0) this.$refs.widget.style.top = 0
          break
        }
        case 'pin': {
          this.$refs.widget.style.left = ''
          this.$refs.widget.style.top = ''
          break
        }
      }
    },
  },
}
</script>

<style lang="scss">
.widget-container {
  @apply bg-v-ui-root-monochrome-5 overflow-hidden;
  box-shadow: 0px 0px 20px 1px #7474740d;

  &.pin {
    position: absolute;
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    width: 768px;
    max-width: 768px;
    min-height: 100vh;
    height: 100%;

    .widget-indicators-unpin {
      display: none;
    }
  }

  &.unpin {
    resize: both;
    overflow: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 900px;
    max-width: 900px;
    min-width: 700px;
    min-height: 440px;
    max-height: calc(100% - 20px);
    height: calc(100% - 40px);

    .widget-indicators-pin {
      display: none;
    }
  }

  &.wrap {
    position: absolute;
    bottom: 20px !important;
    top: auto !important;
    left: 20px !important;
    height: auto;
    width: 300px;

    .widget-indicators {
      display: none;
    }
  }
}
</style>
