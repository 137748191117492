<template>
  <BModal v-if="modal" @close-modal="closeModal">
    <component :is="modal" @close-modal="closeModal" />
  </BModal>
</template>

<script>
import BModal from '@/components/base/BModal.vue'
import NotSuitableModal from './NotSuitableModal.vue'
import ExcludeFromResearchModal from './ExcludeFromResearchModal.vue'

export default {
  components: {
    BModal,
    NotSuitableModal,
    ExcludeFromResearchModal,
  },
  props: {
    modal: {
      type: String,
      default: 'NotSuitableModal',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>
