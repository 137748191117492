<template>
  <component
    :is="component"
    @click="$emit('click')"
    v-bind="$attrs"
    class="icon"
  />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      return require(`@/assets/icons/${this.name}.svg?inline`)
    },
  },
}
</script>
