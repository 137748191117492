<template>
  <div class="flex flex-col gap-2">
    <div>{{ question.name }}</div>
    <div class="flex gap-2">
      <div
        v-for="option in options"
        :key="option.id"
        class="flex gap-2 rounded-lg bg-v-ui-root-monochrome-5 px-4 py-2 w-full"
      >
        <VRadio :id="option.id" v-model="answer" :current-value="option" />{{
          option.title
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { VRadio } from '@doctis.front/doctis.designsystem'

export default {
  components: {
    VRadio,
  },
  props: {
    question: {
      type: Object,
      default() {
        return {
          id: 0,
          name: 'question',
          type: 0,
        }
      },
    },
    answerKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: [
        {
          id: 0,
          title: 'Да',
          value: 0,
        },
        {
          id: 1,
          title: 'Нет',
          value: 1,
        },
      ],
      answer: null,
    }
  },
  watch: {
    answer(answer) {
      this.$emit('set-answer', { ...this.question, answer: answer.value })
    },
  },
  created() {
    this.answer = this.options[this.answerKey]
  },
}
</script>
