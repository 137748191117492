import axios from 'axios'
import store from '@/store'

export const config = {
  baseURL: process.env.VUE_APP_API,
  headers: {
    AppType: 'doctorApp',
  },
}

export default class Api {
  constructor(options = {}) {
    this.client = options.client || axios.create(config)

    this.client.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: store.state.auth.token,
        },
      }
    })
  }
}
