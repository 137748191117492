<template>
  <div class="flex flex-col w-[600px]">
    <VPanelHeaderSimple
      class="rounded-t-2xl pt-6 px-6"
      title-size="l"
      :border="true"
      title="Вы собираетесь исключить пациента из исследования"
      :options="panelHeaderOptions"
    />
    <div class="flex flex-col pt-4 px-6 pb-6">
      <p>Выберите причину исключения из списка</p>
      <div class="mt-6 flex flex-col rounded-t-lg">
        <div
          v-for="option in options"
          :key="option.id"
          class="exception-reason flex gap-2 bg-v-ui-root-monochrome-5 px-4 py-2 w-full border-b border-v-ui-root-monochrome-10"
        >
          <div class="min-w-5">
            <VRadio
              :id="option.id"
              v-model="exceptionReason"
              :current-value="option"
            />
          </div>
          {{ option.reason }}
        </div>
      </div>
      <div class="flex justify-end mt-4">
        <VButton
          label="Подтвердить"
          size="m"
          :loading="isExcludeProcess"
          @click="excludeFromResearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CoreApi from '@/api/coreApi'
const coreApi = new CoreApi()

import {
  VPanelHeaderSimple,
  VRadio,
  VButton,
} from '@doctis.front/doctis.designsystem'

export default {
  components: {
    VPanelHeaderSimple,
    VRadio,
    VButton,
  },
  data() {
    return {
      exceptionReason: null,
      panelHeaderOptions: {
        actions: [
          {
            name: 'cross',
            handler: this.closeModal,
          },
        ],
      },
      options: [],
      isLoading: false,
      isExcludeProcess: false,
    }
  },
  computed: {
    ...mapState('research', ['research']),
  },
  async mounted() {
    const params = {
      assignment: this.research.assignment_id,
    }
    try {
      this.isLoading = true
      this.options = await coreApi.reasonForExclusionByAssignment(params)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      researchByPatient: 'research/researchByPatient',
    }),
    async excludeFromResearch() {
      if (this.exceptionReason) {
        const params = {
          assignment: this.research.assignment_id,
          reason_id: this.exceptionReason.id,
        }
        try {
          this.isExcludeProcess = true
          this.options = await coreApi.excludeFromAssignment(params)
          await this.researchByPatient(true)
        } finally {
          this.isExcludeProcess = false
        }
        this.closeModal()
      }
    },
    closeModal() {
      this.$emit('close-modal')
    },
  },
}
</script>

<style lang="scss">
.exception-reason {
  &:first-child {
    @apply rounded-t-lg;
  }

  &:last-child {
    @apply rounded-b-lg border-b-0;
  }
}
</style>
