<template>
  <div class="px-6">
    <VPanelHeaderSimple
      class="my-4"
      :options="headerOptions"
      title-size="m"
      title="Анкета пациента"
    />
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-2">
        <PatientInfo />
        <SelectResearch
          class="pr-2"
          title="Выберите исследование"
          :opened="true"
          :options="researches"
          :value="research"
          @select-option="selectResearch"
          @on-open="setScrollContainerHeigth"
        />
        <div id="scroll-container" class="flex overflow-hidden">
          <div
            class="w-full flex flex-col gap-2 overflow-y-auto custom-scrollbar pr-2"
          >
            <FormItemContainer
              :title="consentConfirmedTitle"
              :is-active="uploadFileIsAvailable"
              :opened="uploadFileIsOpened"
              :completed="downloadIsCompleted"
            >
              <DownloadsContainer v-if="!downloadIsCompleted" />
            </FormItemContainer>
            <FormItemContainer
              :title="inclusionСriteriaTitle"
              :is-active="consentConfirmed || isIncluded"
              :opened="inclusionСriteriaIsOpened"
              :completed="inclusionСriteriaIsCompleted"
            >
              <CriteriaContainer
                :key="`${research?.id}-${research?.assignment_status}`"
                :criteria-type="0"
                @confirm-criteria="confirmInclusionCriteria"
              />
            </FormItemContainer>
            <FormItemContainer
              :title="nonInclusionСriteriaTitle"
              :is-active="inclusionСriteriaStatus"
              :opened="nonInclusionСriteriaIsOpened"
              :completed="nonInclusionСriteriaIsCompleted"
            >
              <CriteriaContainer
                :key="`${research?.id}-${research?.assignment_status}`"
                :criteria-type="1"
                @confirm-criteria="confirmNoninclusionCriteria"
              />
            </FormItemContainer>
            <FormItemContainer
              :title="subjectGroupTitle"
              :is-active="nonInclusionСriteriaStatus"
              :opened="subjectGroupIsOpened"
              :completed="subjectGroupIsCompleted"
            >
              <SubjectGroupContainer @group-added="groupCompleted" />
            </FormItemContainer>
            <div
              v-if="isShowExcludeBtn"
              class="px-5 py-4 rounded-xl bg-v-ui-root-monochrome-0"
            >
              <VButton
                label="Исключить из исследования"
                size="m"
                type="outline"
                @click="excludeFromResearch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalContainer :modal="modalName" @close-modal="closeModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { format } from '@evd3v/date-fns'
import { SUBJECT_GROUP } from '@/consts'

import { VPanelHeaderSimple, VButton } from '@doctis.front/doctis.designsystem'
import PatientInfo from '@/components/form/patient/PatientInfo.vue'
import SelectResearch from '@/components/form/researches/SelectResearch.vue'
import FormItemContainer from './FormItemContainer.vue'
import DownloadsContainer from './downloads/DownloadsContainer.vue'
import CriteriaContainer from './criteria/CriteriaContainer.vue'
import SubjectGroupContainer from './subject-group/SubjectGroupContainer.vue'
import ModalContainer from '@/components/modals/ModalContainer.vue'

export default {
  components: {
    VPanelHeaderSimple,
    VButton,
    PatientInfo,
    SelectResearch,
    FormItemContainer,
    DownloadsContainer,
    CriteriaContainer,
    SubjectGroupContainer,
    ModalContainer,
  },
  data() {
    return {
      headerOptions: {
        colorMode: 'gray',
      },

      uploadFileIsAvailable: false,
      uploadFileIsOpened: false,
      downloadIsCompleted: false,

      inclusionСriteriaIsOpened: false,
      inclusionСriteriaIsCompleted: false,
      inclusionСriteriaStatus: false,

      nonInclusionСriteriaIsOpened: false,
      nonInclusionСriteriaIsCompleted: false,
      nonInclusionСriteriaStatus: false,

      subjectGroupIsOpened: false,
      subjectGroupIsCompleted: false,
      subjectGroupStatus: false,

      modalName: '',
    }
  },
  computed: {
    ...mapState('research', [
      'researches',
      'research',
      'consentConfirmed',
      'signingDate',
    ]),
    isIncluded() {
      return this.research?.assignment_status === 0
    },
    isCompleted() {
      return this.research?.assignment_status === 1
    },
    consentConfirmedTitle() {
      const className =
        this.consentConfirmed || this.downloadIsCompleted || this.isIncluded
          ? 'text-v-ui-root-service-info-colored'
          : 'text-v-ui-text-monochrome'

      let label =
        'Загрузите файл и заполните дату подписания субъектом информированного согласия на участие в исследовании'
      if (this.consentConfirmed || this.downloadIsCompleted) {
        const date = this.signingDate
          ? format(this.signingDate, 'dd.LL.yyyy')
          : ''
        label = `Информированное согласие подписано ${date}`
      }

      return { className, label }
    },
    inclusionСriteriaTitle() {
      const className =
        this.inclusionСriteriaIsCompleted || this.isIncluded
          ? 'text-v-ui-root-service-info-colored'
          : 'text-v-ui-text-monochrome'

      let label = 'Критерии включения'

      if (this.inclusionСriteriaIsCompleted && this.inclusionСriteriaStatus) {
        label = `Подходит по критериям включения`
      }
      if (this.inclusionСriteriaIsCompleted && !this.inclusionСriteriaStatus) {
        label = `Не подходит по критериям включения`
      }

      return { className, label }
    },
    nonInclusionСriteriaTitle() {
      const className = this.nonInclusionСriteriaIsCompleted
        ? 'text-v-ui-root-service-info-colored'
        : 'text-v-ui-text-monochrome'

      let label = 'Критерии невключения'

      if (
        this.nonInclusionСriteriaIsCompleted &&
        this.nonInclusionСriteriaStatus
      ) {
        label = `Нет критериев невключения`
      }
      if (
        this.nonInclusionСriteriaIsCompleted &&
        !this.nonInclusionСriteriaStatus
      ) {
        label = `Есть критерии невключения`
      }

      return { className, label }
    },
    subjectGroupTitle() {
      const className = this.subjectGroupIsCompleted
        ? 'text-v-ui-root-service-info-colored'
        : 'text-v-ui-text-monochrome'

      let label = 'Группа субъекта'

      if (
        this.subjectGroupIsCompleted &&
        Number.isInteger(this.subjectGroupStatus)
      ) {
        label = SUBJECT_GROUP[this.subjectGroupStatus].title
      }

      return { className, label }
    },
    isShowExcludeBtn() {
      return this.subjectGroupIsCompleted && !this.isCompleted
    },
  },
  watch: {
    research: {
      handler(value, oldValue) {
        if (value?.id !== oldValue?.id) {
          this.resetResearch()
        }

        const isExcluded = this.research.assignment_status !== 2

        if (value && isExcluded) this.uploadFileIsAvailable = true
        if (Number.isInteger(value.assignment_status)) {
          this.downloadIsCompleted = true
          this.uploadFileIsOpened = false
        } else {
          this.downloadIsCompleted = false
          this.uploadFileIsOpened = true
        }
      },
      deep: true,
    },
    consentConfirmed(value) {
      if (value) {
        this.uploadFileIsOpened = false
        this.inclusionСriteriaIsOpened = !this.inclusionСriteriaIsCompleted
      } else {
        this.uploadFileIsOpened = !this.isIncluded
      }
    },
    downloadIsCompleted(value) {
      if (value) {
        this.inclusionСriteriaIsOpened = !this.inclusionСriteriaIsCompleted
      }
    },
  },
  mounted() {
    this.setScrollContainerHeigth()
  },
  methods: {
    ...mapActions({
      setResearch: 'research/setResearch',
    }),
    selectResearch(research) {
      this.setResearch(research)
      this.uploadFileIsOpened = true
    },
    confirmInclusionCriteria(status) {
      if (!status) this.modalName = 'NotSuitableModal'
      this.inclusionСriteriaIsOpened = false
      this.inclusionСriteriaIsCompleted = true
      this.inclusionСriteriaStatus = status
      if (status && this.inclusionСriteriaIsCompleted) {
        this.nonInclusionСriteriaIsOpened = true
      }
    },
    confirmNoninclusionCriteria(status) {
      if (!status) this.modalName = 'NotSuitableModal'
      this.nonInclusionСriteriaIsOpened = false
      this.nonInclusionСriteriaIsCompleted = true
      this.nonInclusionСriteriaStatus = status
      if (status) {
        this.subjectGroupIsOpened = true
      }
    },
    groupCompleted(group) {
      this.subjectGroupStatus = group
      this.subjectGroupIsOpened = false
      this.subjectGroupIsCompleted = true
    },
    setScrollContainerHeigth() {
      const widgetContainer = document.querySelector('#widget-container')
      const widgetContainerRect = widgetContainer.getBoundingClientRect()

      const scrollContainer = document.querySelector('#scroll-container')
      const scrollContainerRect = scrollContainer.getBoundingClientRect()

      const scrollContainerHeigth =
        widgetContainerRect.height - scrollContainerRect.top - 24
      scrollContainer.style.height = `${scrollContainerHeigth}px`
    },
    resetResearch() {
      this.uploadFileIsAvailable = false
      this.uploadFileIsOpened = false
      this.downloadIsCompleted = this.isIncluded || this.isCompleted

      this.inclusionСriteriaIsOpened = false
      this.inclusionСriteriaIsCompleted = this.isIncluded || this.isCompleted
      this.inclusionСriteriaStatus = this.isIncluded || this.isCompleted

      this.nonInclusionСriteriaIsOpened = false
      this.nonInclusionСriteriaIsCompleted = this.isIncluded || this.isCompleted
      this.nonInclusionСriteriaStatus = this.isIncluded || this.isCompleted

      this.subjectGroupIsOpened = false
      this.subjectGroupIsCompleted = this.isIncluded || this.isCompleted
      this.subjectGroupStatus = this.isIncluded || this.isCompleted
    },
    excludeFromResearch() {
      this.modalName = 'ExcludeFromResearchModal'
    },
    closeModal() {
      this.modalName = ''
    },
  },
}
</script>

<style></style>
