import Vue from 'vue'
import App from './App.vue'
import VueCustomElement from 'vue-custom-element'
import Icon from '@/components/base/Icon'
import drag from 'v-drag'
import 'document-register-element/build/document-register-element'
import '@/assets/scss/main.scss'

import { WidgetApi } from '@/widget-api'

Vue.config.productionTip = false

Vue.use(VueCustomElement)
Vue.use(drag)
Vue.customElement('d-widget', App)

Vue.component('Icon', Icon)

const dwidgetclinicalresearches = new WidgetApi(App)
window.dwidgetclinicalresearches = dwidgetclinicalresearches
