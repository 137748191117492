<template>
  <div class="relative flex flex-col gap-6">
    <DownloadFileVue
      :file-is-confirmed="fileIsConfirmed"
      @set-file="setConfirmStatus"
    />
    <SigningDate />
    <div>
      <VButton label="Подтвердить" size="m" @click="confirmFileAndDate" />
    </div>
    <div
      v-if="isLoading"
      class="absolute flex justify-center items-center w-full h-full bg-v-ui-overlay-monochrome-10"
    >
      <Preloader />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { format } from '@evd3v/date-fns'
import { VButton } from '@doctis.front/doctis.designsystem'
import DownloadFileVue from './DownloadFile.vue'
import SigningDate from './SigningDate.vue'
import Preloader from '@/components/base/Preloader.vue'

export default {
  components: {
    VButton,
    DownloadFileVue,
    SigningDate,
    Preloader,
  },
  data() {
    return {
      isLoading: false,
      fileIsConfirmed: true,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'researches']),
    ...mapState('research', ['research', 'file', 'signingDate']),
  },
  methods: {
    ...mapActions({
      assignmentCreate: 'research/assignmentCreate',
      researchByPatient: 'research/researchByPatient',
      setResearch: 'research/setResearch',
    }),
    setConfirmStatus(value) {
      this.fileIsConfirmed = value
    },
    async confirmFileAndDate() {
      if (this.signingDate) {
        this.setConfirmStatus(true)

        const params = {
          research: this.research.id,
          medialog_id: this.user.id,
          consent_file: this.file || '',
          date_of_signing: format(this.signingDate, 'yyyy-LL-dd'),
        }
        try {
          this.isLoading = true

          await this.assignmentCreate(params)

          await this.researchByPatient(true)

          const updatesResearch = this.researches.find(
            (research) => research.id === this.research.id
          )
          this.setResearch(updatesResearch)
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
