<template>
  <div class="preloader" :data-small="small">
    <div v-if="dots" class="dots">
      <div
        v-for="index in 3"
        :key="index"
        class="dot"
        :style="`background-color: ${color} `"
      ></div>
    </div>
    <div v-else class="lds-ring" :class="containerClass">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped lang="scss">
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .dots {
    display: flex;

    .dot {
      width: 10px;
      height: 10px;
      background-color: var(--dark);
      border-radius: 50%;
      transition: all 0.2s;
      animation: dot 1.5s infinite linear;
      opacity: 1;
      transform: scale(1);
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @keyframes dot {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      13.33% {
        opacity: 0;
        transform: scale(0.5);
      }
      40% {
        opacity: 0;
        transform: scale(0.5);
      }
      53.33% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .lds-ring {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 4px solid #75dfcf;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #75dfcf transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    &.small {
      width: 50px;
      height: 50px;
      div {
        width: 32px;
        height: 32px;
        border-width: 2px;
      }
    }
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
