<template>
  <div
    class="fixed inset-0 w-screen h-screen flex justify-center items-center bg-v-ui-overlay-monochrome-40"
    @click.stop="close"
    @keydown.esc.stop="close"
  >
    <div
      class="rounded-2xl bg-v-ui-root-monochrome-0"
      @keydown.esc="close"
      @click.stop
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close-modal')
    },
  },
}
</script>
