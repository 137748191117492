<template>
  <div
    class="flex justify-between items-center gap-4 bg-v-ui-root-monochrome-0 rounded-lg py-5 px-4 mr-2"
  >
    <div class="flex flex-col gap-1">
      <div class="title-16-medium-500">{{ patientFullName }}</div>
      <div class="text-14-reg-400 text-v-ui-text-monochrome-80">
        {{ patientDescription }}
      </div>
    </div>
    <VButton
      v-if="isShowBtn"
      :label="btnData.label"
      size="m"
      :loading="isLoading"
      @click="btnData.action"
    />
  </div>
</template>

<script>
import CoreApi from '@/api/coreApi'
import { mapState, mapActions, mapMutations } from 'vuex'
import { declOfNum } from '@/helpers'
import { VButton } from '@doctis.front/doctis.designsystem'

const coreApi = new CoreApi()

export default {
  components: {
    VButton,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('research', ['research']),
    patientFullName() {
      return this.user.name
    },
    patientDescription() {
      const gender = this.user.gender === 'male' ? 'Мужчина' : 'Женщина'
      const decline = declOfNum(this.user.age, ['год', 'года', 'лет'])

      return `${gender}, ${this.user.age} ${decline}`
    },
    isIncluded() {
      return this.research?.assignment_status === 0
    },
    isScreenfailer() {
      return this.research?.assignment_status === 3
    },
    btnData() {
      const complete = {
        label: 'Завершить исследование',
        action: this.completeResearch,
      }
      const repeat = {
        label: 'Повторить проверку',
        action: this.repeatResearch,
      }
      return this.isIncluded ? complete : repeat
    },
    isShowBtn() {
      return this.isIncluded || this.isScreenfailer
    },
  },
  methods: {
    ...mapActions({
      researchByPatient: 'research/researchByPatient',
    }),
    ...mapMutations({
      setConsentConfirmed: 'research/setConsentConfirmed',
    }),
    async completeResearch() {
      try {
        this.isLoading = true
        const params = {
          assignment: this.research.assignment_id,
        }
        await coreApi.completeAssignment(params)
        await this.researchByPatient(true)
      } finally {
        this.isLoading = false
      }
    },
    repeatResearch() {
      this.setConsentConfirmed(true)
    },
    handleBtn() {
      console.log('handleBtn')
    },
  },
}
</script>
