<template>
  <div class="widget-indicators">
    <Icon name="wrap" @click="setWidgetState('wrap')" />
    <Icon :name="pinIcon" @click="pinToggle" />
    <Icon name="close" @click="setWidgetState('close')" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'WidgetIndicators',
  computed: {
    ...mapState(['widgetState']),
    pinIcon() {
      return this.widgetState === 'unpin' ? 'pin' : 'unpin'
    },
  },
  methods: {
    ...mapMutations(['setWidgetState']),
    pinToggle() {
      this.setWidgetState(this.widgetState === 'unpin' ? 'pin' : 'unpin')
    },
  },
}
</script>

<style lang="scss">
.widget-indicators {
  position: absolute;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .icon {
    fill: var(--dont-gray);
    margin-right: 24px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      fill: var(--dark);
    }
  }
}
</style>
