import Api from '@/api/api'

class CoreApi extends Api {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.VUE_APP_API
  }

  async researchByPatient(medialog_id) {
    const params = { medialog_id }

    const { data } = await this.client.get('/ctc/research_by_patient', {
      params,
    })
    return data
  }

  async assignmentCreate(params) {
    const formData = new FormData()
    for (let key in params) {
      formData.append(key, params[key])
    }

    const { data } = await this.client.post(
      '/ctc/assignment_create/',
      formData,
      {
        headers: {
          enctype: 'multipart/form-data',
        },
      }
    )
    return data
  }

  async inclusionCriterials(params) {
    const { data } = await this.client.get('/ctc/get_inclusion_criterials', {
      params,
    })
    return data
  }

  async addAssignmentSelectCriterials(params) {
    const { data } = await this.client.post(
      '/ctc/add_assignment_select_criterials/',
      params
    )
    return data
  }

  async addGroup(params) {
    const { data } = await this.client.post(
      '/ctc/add_group_to_assignment/',
      params
    )
    return data
  }

  async reasonForExclusionByAssignment(params) {
    const { data } = await this.client.get(
      '/ctc/reason_for_exclusion_by_assignment/',
      {
        params,
      }
    )
    return data
  }

  async excludeFromAssignment(params) {
    const { data } = await this.client.post(
      '/ctc/exclude_from_assignment/',
      params
    )
    return data
  }

  async completeAssignment(params) {
    const { data } = await this.client.post('/ctc/complete_assignment/', params)
    return data
  }
}

export default CoreApi
