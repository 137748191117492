<template>
  <div class="flex flex-col gap-1">
    <div
      class="flex px-6 py-4 bg-v-ui-root-monochrome-0 rounded-lg cursor-pointer"
      @click="onOpenToggle()"
    >
      <div class="flex justify-between items-center mr-3 w-full">
        <h2 class="title-20-medium-500" :class="[selectedResearch.className]">
          {{ selectedResearch.title }}
        </h2>
        <VAttribute
          v-if="selectedResearch.label"
          :key="selectedResearch.attribute.key"
          v-bind="selectedResearch.attribute"
        />
      </div>
      <div>
        <VIcon
          class="text-v-ui-root-service-info-colored transition-all duration-500"
          :class="{ 'transform rotate-180': isOpen }"
          name="m/arrow_down_chevron"
        />
      </div>
    </div>
    <div
      v-if="getOptions.length"
      class="flex flex-col gap-6 px-6 py-4 bg-v-ui-root-monochrome-0 rounded-lg"
      :class="isOpen ? 'visible' : 'hidden'"
    >
      <div
        v-for="option in getOptions"
        :key="option.id"
        class="w-full flex justify-between gap-2"
        @click="selectOption(option)"
      >
        <h2
          class="title-20-medium-500 mr-3 w-full cursor-pointer"
          :class="
            option.selected
              ? 'text-v-ui-root-service-info-colored'
              : 'text-v-ui-text-monochrome'
          "
        >
          {{ option.code }}
        </h2>
        <VAttribute
          v-if="Number.isInteger(option.assignment_status)"
          v-bind="statusAttributeData(option.assignment_status)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ASSIGNMENT_STATUS } from '@/consts'
import { VIcon, VAttribute } from '@doctis.front/doctis.designsystem'

export default {
  name: 'VAccordionListItem',
  components: { VIcon, VAttribute },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Accordion title',
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    value: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState('research', ['research', 'consentConfirmed', 'signingDate']),
    getOptions() {
      return this.options.map((option) => ({
        ...option,
        selected: option.id === this.value?.id ? true : false,
      }))
    },
    selectedResearch() {
      const className = this.value?.code
        ? 'text-v-ui-root-service-info-colored'
        : 'text-v-ui-text-monochrome'
      const title = this.value?.code ? this.value.code : this.title
      const label = Boolean(this.value?.code)

      let attribute = null

      if (label) {
        const attributeId = Number.isInteger(this.value.assignment_status)
          ? this.value.assignment_status
          : 'default'
        attribute = {
          content: ASSIGNMENT_STATUS[attributeId].label,
          'left-icon': ASSIGNMENT_STATUS[attributeId].icon,
          variant: ASSIGNMENT_STATUS[attributeId].variant,
          key: attributeId,
        }
      }

      return { className, title, label, attribute }
    },
  },
  watch: {
    getOptions() {
      if (!this.value && this.options.length === 1) {
        this.selectOption(this.options[0])
      }
    },
    isOpen() {
      setTimeout(() => {
        this.$emit('on-open')
      }, 600)
    },
  },
  mounted() {
    if (this.opened) this.onOpenToggle()
  },
  methods: {
    onOpenToggle() {
      this.isOpen = !this.isOpen
    },
    statusAttributeData(status) {
      if (Number.isInteger(status)) {
        return {
          content: ASSIGNMENT_STATUS[status].label,
          'left-icon': ASSIGNMENT_STATUS[status].icon,
          variant: ASSIGNMENT_STATUS[status].variant,
        }
      }
    },
    selectOption(option) {
      this.$emit('select-option', option)
      this.onClose()
    },
    onClose() {
      this.isOpen = false
    },
  },
}
</script>
