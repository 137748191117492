export class WidgetApi {
  constructor(app) {
    this.app = app
    this.initiated = false
  }

  async initiate() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    await this.app.store.dispatch('auth/init', params)
    this.initiated = true
  }
}
