import jwtEncode from 'jwt-encode'

const Auth = {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  actions: {
    async init({ state, dispatch, commit }, params) {
      // generate token for develop

      // const jti = Math.floor(
      //   10000000000000000000000000000000 + Math.random() * 900000
      // )
      // const exp = Date.now() / 1000 + 60000
      // const payload = {
      //   token_type: 'access',
      //   exp,
      //   jti,
      //   user_id: 'medialog_user',
      // }
      // const token = jwtEncode(payload, process.env.VUE_APP_SECRET_KEY)

      const token = params.token
      commit('setToken', token)
      const user = {
        id: params.id,
        name: `${params.surname} ${params.name} ${params.lastname}`,
        gender: params.gender,
        age: params.age,
      }
      commit('setUser', user)
      await dispatch('research/researchByPatient', false, { root: true })
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = `Bearer ${token}`
    },
    setUser(state, user) {
      state.user = user
    },
  },
}

export default Auth
