<template>
  <div
    v-if="!isActive"
    class="flex flex-col gap-1 bg-v-ui-root-monochrome-0 rounded-lg px-6 py-4 title-20-medium-500 text-v-ui-text-monochrome-27"
  >
    {{ title.label }}
  </div>
  <div
    v-else
    class="flex flex-col gap-1 px-6 py-4 bg-v-ui-root-monochrome-0 rounded-lg"
  >
    <div
      class="flex cursor-pointer"
      :class="{ 'w-full border-b border-v-ui-root-monochrome-10 pb-4': isOpen }"
      @click="onOpenToggle()"
    >
      <div class="flex justify-between items-center mr-3 w-full">
        <h2 class="title-20-medium-500" :class="title.className">
          {{ title.label }}
        </h2>
      </div>
      <div v-if="!completed">
        <VIcon
          class="text-v-ui-root-main transition-all duration-500"
          :class="{ 'transform rotate-180': isOpen }"
          name="m/arrow_down_chevron"
        />
      </div>
    </div>
    <div
      class="flex flex-col gap-6 pt-4 pb-2 bg-v-ui-root-monochrome-0 rounded-lg"
      :class="isOpen ? 'visible' : 'hidden'"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { VIcon } from '@doctis.front/doctis.designsystem'

export default {
  components: { VIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Object,
      default() {
        return {
          label: 'item title',
        }
      },
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  watch: {
    opened(value) {
      if (value && this.isActive) {
        this.isOpen = true
      } else {
        this.isOpen = false
      }
    },
  },
  methods: {
    onOpenToggle() {
      if (!this.completed) {
        this.isOpen = !this.isOpen
        this.$emit('onClick', this.isOpen)
      }
    },
    selectOption(option) {
      this.$emit('result', option)
      this.onClose()
    },
    onClose() {
      this.isOpen = false
    },
  },
}
</script>
