export const SUBJECT_GROUP = {
  0: { id: 0, title: 'Исследуемая группа', value: 0 },
  1: { id: 1, title: 'Контрольная группа', value: 1 },
}

export const ASSIGNMENT_STATUS = {
  default: {
    id: 1000,
    name: 'selected',
    label: 'Исследование\u00A0выбрано',
    variant: 'info',
  },
  0: {
    id: 0,
    name: 'included',
    label: 'Включен',
    variant: 'warning',
    icon: {
      name: 'alert',
    },
  },
  1: {
    id: 1,
    name: 'completed',
    label: 'Завершил',
    variant: 'success',
    icon: {
      name: 'check',
    },
  },
  2: {
    id: 2,
    name: 'excluded',
    label: 'Исключен',
    variant: 'alarm',
    icon: {
      name: 'cross',
    },
  },
  3: {
    id: 3,
    name: 'screenfailer',
    label: 'Скринфэйлер',
    variant: 'info',
    icon: {
      name: 'visibility-off',
    },
  },
  4: {
    id: 4,
    name: 'in_screening',
    label: 'В\u00A0скрининге',
    variant: 'info-colored',
    icon: {
      name: 'alert',
    },
  },
}
