import Api from '@/api/api'

export default class ServiceApi extends Api {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.VUE_APP_OLD_API
  }

  registerProfileDevice() {
    return this.client.post('/v1/profile/devices', {
      DeviceId: '',
      ApplicationType: 'DoctorApp',
      ClientPlatform: 'WebBrowser',
      CertificateType: 0,
    })
  }
}
