import { coreRequest } from '@/api'

const Research = {
  namespaced: true,
  state: {
    researches: [],
    research: null,

    file: null,
    signingDate: null,
    consentConfirmed: false,
  },
  actions: {
    async researchByPatient({ rootState, state, commit }, needUpdate = false) {
      const medialog_id = rootState.auth.user.id
      const researches = await coreRequest.researchByPatient(medialog_id)

      commit('setResearches', researches)
      if (!needUpdate) {
        commit('setInit', true, { root: true })
      }

      if (needUpdate) {
        const research = researches.find(
          (research) => research.id === state.research.id
        )
        commit('setSelectedResearch', research)
        const consentConfirmed = Number.isInteger(research.assignment_status)

        commit('setConsentConfirmed', consentConfirmed)
      }
    },
    setResearch({ commit }, research) {
      commit('setSelectedResearch', research)
      const hasStatus = Number.isInteger(research.assignment_status)

      const isCompleted = research?.assignment_status === 1
      const inScreening = research?.assignment_status === 4
      const validStatus = inScreening || isCompleted

      let consentConfirmed = hasStatus && validStatus

      commit('setConsentConfirmed', consentConfirmed)
    },
    setFile({ commit }, file) {
      commit('setSelectedFile', file)
    },
    setDate({ commit }, date) {
      commit('setSigningDate', date)
    },
    async assignmentCreate({ commit }, params) {
      try {
        const assignment = await coreRequest.assignmentCreate(params)
        commit('setConsentConfirmed', true)
        return assignment
      } catch (error) {
        return null
      }
    },
  },
  mutations: {
    setResearches(state, researches) {
      state.researches = [...researches]
    },
    setSelectedResearch(state, research) {
      state.research = research

      if (!Number.isInteger(research.assignment_status)) {
        state.signingDate = new Date()
      } else {
        state.signingDate = null
      }
    },
    setSelectedFile(state, file) {
      state.file = file
    },
    setSigningDate(state, date) {
      state.signingDate = date
    },
    setConsentConfirmed(state, value) {
      state.consentConfirmed = value
    },
  },
}

export default Research
