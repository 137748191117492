import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import research from '@/store/modules/research'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    init: false,
    widgetState: 'pin', // close, wrap, unpin
  },
  mutations: {
    setWidgetState(state, widgetState) {
      if (widgetState === 'close') state.init = false
      else state.widgetState = widgetState
    },
    setInit(state, init) {
      state.init = init
    },
  },
  getters: {},
  modules: {
    namespaced: true,
    auth,
    research,
  },
})
