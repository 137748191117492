<template>
  <div class="flex flex-col gap-6">
    <div v-if="downloadTemplateLink" class="flex flex-col gap-2">
      <div class="title-14-medium-500 text-v-ui-text-monochrome-55">
        Скачайте согласие на участие в исследовании
      </div>
      <div class="flex">
        <a
          :href="downloadTemplateLink"
          target="_blank"
          class="text-v-ui-root-main cursor-pointer border-b border-v-ui-line-main w-fit leading-none"
        >
          Бланк согласия.pdf
        </a>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="title-14-medium-500 text-v-ui-text-monochrome-55">
        Загрузите скан подписанного информационного согласия
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-2 p-4 rounded-md" :class="viewClasses">
          <div
            class="font-medium"
            :class="{ 'text-v-ui-root-service-alarm': invalidType }"
          >
            {{ title }}
          </div>
          <div class="flex gap-1 text-14-reg-400 text-v-ui-text-monochrome-80">
            <span
              class="text-v-ui-root-main border-b border-v-ui-line-main cursor-pointer"
              @click="startUploadFile"
              >Загрузите</span
            >
            <input
              id="uploadFile"
              type="file"
              class="hidden"
              @input="uploadFile"
            />
            файл в формате PDF и не более 10 Мб
          </div>
        </div>
        <div
          v-if="file"
          class="flex items-center py-3 px-2 bg-v-ui-root-monochrome-5"
        >
          <div class="p-2 bg-v-ui-text-monochrome-20 rounded">
            <VIcon class="text-v-ui-text-monochrome" name="s/file-base" />
          </div>
          <div class="flex flex-col gap-1 px-2">
            <div class="title-14-medium-500 text-v-ui-text-monochrome-80">
              {{ fileName }}
            </div>
            <div class="note-12-reg-400 text-v-ui-text-monochrome-27">
              {{ fileDescription }}
            </div>
          </div>
          <VIcon
            class="text-v-ui-text-monochrome-20 ml-auto"
            name="m/delet"
            @click="deleteFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns'
import { mapState, mapActions } from 'vuex'
import { TITLE } from '@/consts/file'
import { VIcon } from '@doctis.front/doctis.designsystem'

export default {
  components: {
    VIcon,
  },
  props: {
    fileIsConfirmed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputFile: null,

      fileMaxSize: 10485760,
      fileType: 'application/pdf',

      invalidType: null,
    }
  },
  computed: {
    ...mapState('research', ['research', 'file']),
    downloadTemplateLink() {
      return this.research.file_template || null
    },
    title() {
      return this.invalidType || TITLE.DEFAULT
    },
    viewClasses() {
      return this.invalidType
        ? 'bg-v-ui-light-service-alarm'
        : 'bg-v-ui-root-monochrome-5'
    },
    fileName() {
      return this.file?.name.split('.')[0] || null
    },
    fileDescription() {
      const size = this.bytesToSize(this.file.size)
      const date = format(this.file.lastModified, 'dd.LL.yy')
      const time = format(this.file.lastModified, 'hh:mm')
      return `${size} PDF \u00B7 ${date} в ${time}`
    },
  },
  watch: {
    fileIsConfirmed(value) {
      if (!value && !this.file) this.invalidType = TITLE.INVALID_CONFIRM
    },
  },
  mounted() {
    this.inputFile = document.querySelector('#uploadFile')
  },
  methods: {
    ...mapActions({
      setFile: 'research/setFile',
    }),
    clearInputFile() {
      this.inputFile.type = 'text'
      this.inputFile.type = 'file'
    },
    startUploadFile() {
      this.clearInputFile()
      this.inputFile.click()
    },
    uploadFile(e) {
      const file = e.target.files[0]
      const availableSize = file.size < this.fileMaxSize
      const availableType = file.type === this.fileType

      this.invalidType = null

      if (!availableSize) {
        this.invalidType = TITLE.INVALID_SIZE
      }
      if (!availableType) {
        this.invalidType = TITLE.INVALID_FORMAT
      }
      this.deleteFile()
      this.$emit('set-file', true)
      if (availableSize && availableType) this.setFile(file)
    },
    deleteFile() {
      this.clearInputFile()
      this.setFile(null)
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes == 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i == 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
  },
}
</script>
